var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row-bg", style: { height: _vm.autoHeight + "px" } },
        [
          _c(
            "el-card",
            {
              staticClass: "box-card",
              class: _vm.courseInfo.type === "视频" ? "videotoptitle" : "",
            },
            [
              _c("span", { staticClass: "titleName" }, [
                _vm._v(" " + _vm._s(_vm.courseTitle) + " "),
              ]),
            ]
          ),
          _vm.courseInfo.type !== "视频"
            ? _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  !_vm.pickLeftFlag
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-card",
                            {
                              style: {
                                height: _vm.autoHeight * 0.57001 + 270 + "px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "titleName",
                                  staticStyle: {
                                    "font-size": "15px",
                                    "margin-top": "10px",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _vm._v(" 任务列表 "),
                                  _c("i", {
                                    staticClass: "el-icon-s-fold",
                                    staticStyle: {
                                      float: "right",
                                      "font-size": "18px",
                                    },
                                    on: { click: _vm.pickupLeft },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "parentScroll",
                                  style: {
                                    height:
                                      _vm.autoHeight * 0.57001 + 200 + "px",
                                  },
                                },
                                _vm._l(
                                  _vm.taskListArray,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "taskitem",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeTask(
                                                item.id,
                                                index,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " 任务" +
                                                _vm._s(index + 1) +
                                                " " +
                                                _vm._s(item.title) +
                                                " "
                                            ),
                                          ]),
                                          _c("el-row", [
                                            _c(
                                              "span",
                                              { staticClass: "customBut" },
                                              [_vm._v(_vm._s(item.type))]
                                            ),
                                            item.practicePurpose === "Y"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "customButone",
                                                  },
                                                  [_vm._v("作业")]
                                                )
                                              : _vm._e(),
                                            item.learnPurpose === "Y"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "customButtwo",
                                                  },
                                                  [_vm._v("学员任务")]
                                                )
                                              : _vm._e(),
                                          ]),
                                          _c("div", { staticClass: "line" }),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { span: 0.5 },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-unfold",
                            staticStyle: { "font-size": "18px" },
                            on: { click: _vm.spreadOutLeft },
                          }),
                        ]
                      ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { span: _vm.middleWidth },
                    },
                    [
                      _c(
                        "el-tabs",
                        {
                          model: {
                            value: _vm.activeNameTab,
                            callback: function ($$v) {
                              _vm.activeNameTab = $$v
                            },
                            expression: "activeNameTab",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "课件预览", name: "first" } },
                            [
                              _c(
                                "el-card",
                                {
                                  staticStyle: {
                                    "line-height": "50px",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.taskInfo.title) + " "),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "customBut",
                                      staticStyle: { "margin-left": "15px" },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.taskInfo.type))]
                                  ),
                                  _vm.taskInfo.practicePurpose === "Y"
                                    ? _c(
                                        "span",
                                        { staticClass: "customButone" },
                                        [_vm._v("课堂练习")]
                                      )
                                    : _vm._e(),
                                  _vm.taskInfo.learnPurpose === "Y"
                                    ? _c(
                                        "span",
                                        { staticClass: "customButtwo" },
                                        [_vm._v("学员可用")]
                                      )
                                    : _vm._e(),
                                  _vm.sourceContent !== ""
                                    ? _c(
                                        "span",
                                        { staticStyle: { float: "right" } },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-full-screen",
                                            attrs: { size: "24" },
                                            on: { click: _vm.previewCourse },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm.sourceContent !== "" &&
                              _vm.sourceContent.indexOf("/") > -1
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        style: {
                                          height:
                                            _vm.autoHeight * 0.51412 + "px",
                                        },
                                        attrs: { id: "waterMaskId" },
                                      },
                                      [
                                        _c("iframe", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                          },
                                          attrs: {
                                            src: _vm.sourceContent,
                                            allowfullscreen: "true",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.sourceContent !== "" &&
                                        _vm.sourceContent.indexOf("/") == -1,
                                      expression:
                                        "\n                sourceContent !== '' && sourceContent.indexOf('/') == -1\n              ",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                    overflow: "auto",
                                  },
                                },
                                [
                                  _c("LookPpt", {
                                    ref: "pptIframe",
                                    attrs: { content: _vm.sourceContent },
                                    on: {
                                      changeTaskByChild: _vm.changeTaskByChild,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.sourceContent,
                                      expression: "!sourceContent",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: _vm.autoHeight * 0.61412 + "px",
                                      },
                                    },
                                    [_vm._v(" 暂无课件 ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.taskInfo.type != "课件"
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "任务详情", name: "second" },
                                },
                                [
                                  _c(
                                    "el-card",
                                    {
                                      staticStyle: {
                                        "line-height": "50px",
                                        "margin-top": "10px",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.taskInfo.title) + " "
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "customBut",
                                          staticStyle: {
                                            "margin-left": "15px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.taskInfo.type)
                                          ),
                                        ]
                                      ),
                                      _vm.taskInfo.practicePurpose === "Y"
                                        ? _c(
                                            "span",
                                            { staticClass: "customButone" },
                                            [_vm._v("课堂练习")]
                                          )
                                        : _vm._e(),
                                      _vm.taskInfo.learnPurpose === "Y"
                                        ? _c(
                                            "span",
                                            { staticClass: "customButtwo" },
                                            [_vm._v("学员可用")]
                                          )
                                        : _vm._e(),
                                      _vm.showTaskFlag &&
                                      (_vm.currenttaskID ||
                                        _vm.taskInfo.sourceId)
                                        ? _c(
                                            "span",
                                            { staticStyle: { float: "right" } },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-full-screen",
                                                attrs: { size: "24" },
                                                on: { click: _vm.previewTasks },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-card",
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _vm.showTaskFlag === 1 &&
                                      _vm.currenttaskID
                                        ? _c("previewTaskShow", {
                                            style: {
                                              height:
                                                _vm.autoHeight * 0.52 + "px",
                                            },
                                            attrs: {
                                              taskID: _vm.currenttaskID,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.showTaskFlag === 2 &&
                                      _vm.currenttaskID
                                        ? _c("pdfpreviewShow", {
                                            staticStyle: {
                                              width: "100%",
                                              "overflow-y": "scroll",
                                            },
                                            style: {
                                              height:
                                                _vm.autoHeight * 0.52 + "px",
                                            },
                                            attrs: {
                                              taskID: _vm.currenttaskID,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.showTaskFlag === 3
                                        ? _c("previewVideoShow", {
                                            attrs: {
                                              sourceId: _vm.taskInfo.sourceId,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.showTaskFlag === 4
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: { padding: "16px" },
                                            },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    width: "400",
                                                    trigger: "hover",
                                                  },
                                                  on: {
                                                    hide: function ($event) {
                                                      _vm.formPygame.title = ""
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.pygameTitleVisible,
                                                    callback: function ($$v) {
                                                      _vm.pygameTitleVisible =
                                                        $$v
                                                    },
                                                    expression:
                                                      "pygameTitleVisible",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-form",
                                                        {
                                                          staticClass:
                                                            "demo-form-inline",
                                                          attrs: {
                                                            size: "small",
                                                            inline: false,
                                                            model:
                                                              _vm.formPygame,
                                                          },
                                                          nativeOn: {
                                                            submit: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "作品名称",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "作品名称",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formPygame
                                                                      .title,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.formPygame,
                                                                        "title",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "formPygame.title",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "0 16px",
                                                                  },
                                                                  attrs: {
                                                                    size: "small",
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.submitPygame(
                                                                          "1"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 确定 "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    size: "small",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.pygameTitleVisible = false
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 取消 "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        "border-radius": "16px",
                                                        "margin-right": "16px",
                                                      },
                                                      attrs: {
                                                        slot: "reference",
                                                        size: "small",
                                                        type: "primary",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [_vm._v(" 提交 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "border-radius": "16px",
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "info",
                                                  },
                                                  on: {
                                                    click: _vm.showPygameRecord,
                                                  },
                                                },
                                                [_vm._v(" 提交记录 ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      false
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: { padding: "16px" },
                                            },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    width: "400",
                                                    trigger: "hover",
                                                  },
                                                  on: {
                                                    hide: function ($event) {
                                                      _vm.formScratchJr.title =
                                                        ""
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.scratchJrTitleVisible,
                                                    callback: function ($$v) {
                                                      _vm.scratchJrTitleVisible =
                                                        $$v
                                                    },
                                                    expression:
                                                      "scratchJrTitleVisible",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-form",
                                                        {
                                                          staticClass:
                                                            "demo-form-inline",
                                                          attrs: {
                                                            size: "small",
                                                            inline: false,
                                                            model:
                                                              _vm.formScratchJr,
                                                          },
                                                          nativeOn: {
                                                            submit: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "作品名称",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "作品名称",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formScratchJr
                                                                      .title,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.formScratchJr,
                                                                        "title",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "formScratchJr.title",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "0 16px",
                                                                  },
                                                                  attrs: {
                                                                    size: "small",
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.submitScratchJr(
                                                                          "1"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 确定 "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    size: "small",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.scratchJrTitleVisible = false
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 取消 "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        "border-radius": "16px",
                                                        "margin-right": "16px",
                                                      },
                                                      attrs: {
                                                        slot: "reference",
                                                        size: "small",
                                                        type: "primary",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [_vm._v(" 提交 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "border-radius": "16px",
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "info",
                                                  },
                                                  on: {
                                                    click: _vm.showPygameRecord,
                                                  },
                                                },
                                                [_vm._v(" 提交记录 ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showTaskFlag === 4
                                        ? _c("previewPygameShow", {
                                            ref: "pygameFrame",
                                            style: {
                                              height:
                                                _vm.autoHeight * 0.52 + "px",
                                              overflowY: "auto",
                                            },
                                            attrs: {
                                              sourceId: _vm.taskInfo.sourceId,
                                              taskInfo: _vm.taskInfo,
                                              source: "任务",
                                            },
                                            on: {
                                              previewPygame: _vm.previewPygame,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.showTaskFlag === 5
                                        ? _c("previewScratchJrShow", {
                                            style: {
                                              height:
                                                _vm.autoHeight * 0.52 + "px",
                                              overflowY: "auto",
                                            },
                                            attrs: {
                                              taskInfo: _vm.taskInfo,
                                              sourceId: _vm.taskInfo.sourceId,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-card",
                        { staticClass: "jiaoan" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "titleName",
                              staticStyle: { "font-size": "15px" },
                            },
                            [_vm._v(" 备课教案 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                float: "right",
                              },
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.taskprepareEdit },
                            },
                            [_vm._v("保存 ")]
                          ),
                          _c("el-input", {
                            staticStyle: { padding: "10px" },
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.textarea,
                              callback: function ($$v) {
                                _vm.textarea = $$v
                              },
                              expression: "textarea",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.pickRightFlag
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { span: _vm.rightauto },
                        },
                        [
                          _c(
                            "el-card",
                            [
                              _c(
                                "el-tabs",
                                {
                                  on: { "tab-click": _vm.handleChange },
                                  model: {
                                    value: _vm.activeName,
                                    callback: function ($$v) {
                                      _vm.activeName = $$v
                                    },
                                    expression: "activeName",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { height: "20px" } },
                                    [
                                      (_vm.activeName == "videoTwo" &&
                                        _vm.statusone !== "") ||
                                      (_vm.activeName == "videoOne" &&
                                        _vm.status !== "")
                                        ? _c("i", {
                                            staticClass: "el-icon-full-screen",
                                            staticStyle: {
                                              float: "right",
                                              "font-size": "18px",
                                              "margin-right": "10px",
                                            },
                                            on: { click: _vm.previewVideo },
                                          })
                                        : _vm._e(),
                                      _c("i", {
                                        staticClass: "el-icon-s-unfold",
                                        staticStyle: {
                                          float: "right",
                                          "font-size": "18px",
                                        },
                                        on: { click: _vm.pickupRight },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "说课视频",
                                        name: "videoTwo",
                                      },
                                    },
                                    [
                                      _vm.statusone !== ""
                                        ? _c("div", [
                                            _c("div", {
                                              staticStyle: { margin: "0 auto" },
                                              attrs: { id: "player2" },
                                            }),
                                            _vm.currentArray.length > 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px",
                                                      "line-height": "40px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.currentArray,
                                                    function (item, index) {
                                                      return _c(
                                                        "el-button",
                                                        {
                                                          key: index,
                                                          class:
                                                            _vm.curentOneIndex ===
                                                            index
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            size: "mini",
                                                            round: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeVideo(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                "align-items": "center",
                                              },
                                              style: {
                                                height:
                                                  _vm.autoHeight * 0.25 + "px",
                                              },
                                            },
                                            [_vm._v(" 暂无视频 ")]
                                          ),
                                    ]
                                  ),
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "串讲视频",
                                        name: "videoOne",
                                      },
                                    },
                                    [
                                      _vm.status !== ""
                                        ? _c("div", [
                                            _c("div", {
                                              staticStyle: { margin: "0 auto" },
                                              attrs: { id: "player1" },
                                            }),
                                            _vm.currentArray.length > 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px",
                                                      "line-height": "40px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.currentArray,
                                                    function (item, index) {
                                                      return _c(
                                                        "el-button",
                                                        {
                                                          key: index,
                                                          class:
                                                            _vm.curentOneIndex ===
                                                            index
                                                              ? "active"
                                                              : "",
                                                          attrs: {
                                                            size: "mini",
                                                            round: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeVideo(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content": "center",
                                                "align-items": "center",
                                              },
                                              style: {
                                                height:
                                                  _vm.autoHeight * 0.25 + "px",
                                              },
                                            },
                                            [_vm._v(" 暂无视频 ")]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-card",
                            {
                              staticStyle: {
                                "padding-top": "15px",
                                "margin-top": "10px",
                              },
                              style: {
                                height: _vm.autoHeight * 0.55 + "px",
                                overflowY: "auto",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "titleName",
                                  staticStyle: { "font-size": "15px" },
                                },
                                [_vm._v(" 课程教案 ")]
                              ),
                              _c("div", { attrs: { id: "result" } }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { span: 0.5 },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-fold",
                            staticStyle: { "font-size": "18px" },
                            on: { click: _vm.spreadOutRight },
                          }),
                        ]
                      ),
                ],
                1
              )
            : _c(
                "el-row",
                { staticStyle: { "margin-top": "60px" } },
                [
                  _vm.currenttaskID && _vm.pdfList.length !== 0
                    ? _c(
                        "div",
                        [
                          _c("pdfpreviewShow", {
                            ref: "pdfKejian",
                            attrs: { taskID: _vm.currenttaskID },
                            on: { reloadPover: _vm.reloadPover },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "fixed",
                                right: "0",
                                "z-index": "2000",
                                top: "150px",
                              },
                            },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "left",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "kejianpanrent" },
                                    _vm._l(_vm.pdfList, function (item, index) {
                                      return _c(
                                        "span",
                                        {
                                          key: index,
                                          staticClass: "kejianitem",
                                          class:
                                            _vm.currentKejianIndex === index
                                              ? "kejianactive"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.itemClick(
                                                index,
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(index + 1) +
                                              " . " +
                                              _vm._s(item.title) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { slot: "reference", round: "" },
                                      slot: "reference",
                                    },
                                    [
                                      _c("i", { staticClass: "el-icon-menu" }),
                                      _vm._v("课件 "),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-row",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "18%",
                          },
                        },
                        [_vm._v(" 暂无课件 ")]
                      ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Pygame提交记录",
            visible: _vm.pygameRecordModal,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.pygameRecordModal = $event
            },
          },
        },
        [
          _vm.pygameRecordModal
            ? _c("PygameRecord", {
                ref: "pygameRecord",
                attrs: { courseTask: _vm.courseTask },
                on: { loadToEdit: _vm.previewPygame },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-row class="table" style='padding:0 16px'>
     <el-row class="row-bg" style='padding: 8px 0' v-if='false'>
      <el-col :span="16">
         <el-input
        clearable
        class="tb-sm-input"
        v-model.trim="queryData.title"
        placeholder="请输入名称"
        @keyup.enter.native="search"
      >
      </el-input>
      </el-col>
      <el-col :span="8" style='text-align:right'>
        <el-button
        type="primary"
        size="medium"
        @click="search()"
        class="tb-button"
        >查 询</el-button
      >
        <el-button size="medium" @click="reset()" class="tb-button"
        >重 置</el-button
      >
      </el-col>
     
      
      
    </el-row>
    <el-row>
      <el-col :xs="12" :sm="18" :md="24" :lg="24" :xl="24">
        <el-table
          class="tb-list"
          style="width: 100%; margin-bottom: 20px"
          border
          :data="dataList"
        >
          <el-table-column label="名称" align="center" prop="">
            <template slot-scope="scope">
              {{scope.row.title}}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime | dateFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                style="margin-right: 10px"
                @click="$emit('loadToEdit',scope.row)"
                >加载至编辑区</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if='false'
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="total"
        />
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import {  getWorksProjectByUser } from "@/api/works/index";
export default {
  name: "PygameRecord",
  props:{
    courseTask:{
      type: Object,
      default:()=>{}
    }
  },
  data() {
    return {
      pygameModal: false,
      queryData: {},
      dataList: [],
      titleNameEdit: [],
      myarr: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      title: "",
      pygameDialog: false,
      editData: {},
      detailPygameDialog: false,
      delData: {},
      titleName: "",
      curIndex: -1,
      total:0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let query = {
        ...this.queryData,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        taskId: this.courseTask.taskId,
      };
      let res = await getWorksProjectByUser(query);
      if (res.state == "success") {
        this.dataList = res.body.list;
        this.total = res.body.total;
        this.titleNameEdit = new Array(this.dataList.length).fill(false);
      }
    },

    // 重置
    reset() {
      this.queryData = {};
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getList();
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
</style>
<style>
.full-screen > .is-fullscreen > .el-dialog__header {
  display: none;
}
.full-screen > .is-fullscreen > .el-dialog__body {
  padding: 0;
}
</style>

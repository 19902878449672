<template>
  <div>
    <div class="row-bg" :style="{ height: autoHeight + 'px' }">
      <el-card
        class="box-card"
        :class="courseInfo.type === '视频' ? 'videotoptitle' : ''"
      >
        <span class="titleName">
          {{ courseTitle }}
        </span>
      </el-card>
      <el-row style="margin-top: 20px" v-if="courseInfo.type !== '视频'">
        <el-col :span="4" v-if="!pickLeftFlag">
          <el-card :style="{ height: autoHeight * 0.57001 + 270 + 'px' }">
            <div
              class="titleName"
              style="font-size: 15px; margin-top: 10px; margin-bottom: 10px"
            >
              任务列表
              <i
                class="el-icon-s-fold"
                style="float: right; font-size: 18px"
                @click="pickupLeft"
              ></i>
            </div>
            <div
              class="parentScroll"
              :style="{ height: autoHeight * 0.57001 + 200 + 'px' }"
            >
              <div v-for="(item, index) in taskListArray" :key="index">
                <div class="taskitem" @click="changeTask(item.id, index, item)">
                  <span> 任务{{ index + 1 }} {{ item.title }} </span>
                  <el-row>
                    <span class="customBut">{{ item.type }}</span>
                    <span
                      class="customButone"
                      v-if="item.practicePurpose === 'Y'"
                    >作业</span
                    >
                    <span class="customButtwo" v-if="item.learnPurpose === 'Y'"
                    >学员任务</span
                    >
                  </el-row>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="0.5" v-else style="margin-top: 20px">
          <i
            class="el-icon-s-unfold"
            style="font-size: 18px"
            @click="spreadOutLeft"
          ></i>
        </el-col>
        <el-col :span="middleWidth" style="margin-left: 20px">
          <el-tabs v-model="activeNameTab">
            <el-tab-pane label="课件预览" name="first">
              <el-card style="line-height: 50px; margin-top: 10px">
                <span>{{ taskInfo.title }} </span>
                <span class="customBut" style="margin-left: 15px">
                  {{ taskInfo.type }}</span
                >
                <span
                  class="customButone"
                  v-if="taskInfo.practicePurpose === 'Y'"
                >课堂练习</span
                >
                <span class="customButtwo" v-if="taskInfo.learnPurpose === 'Y'"
                >学员可用</span
                >
                <span v-if="sourceContent !== ''" style="float: right"
                ><i
                  class="el-icon-full-screen"
                  size="24"
                  @click="previewCourse"
                ></i
                ></span>
              </el-card>
              <div
                v-if="sourceContent !== '' && sourceContent.indexOf('/') > -1"
              >
                <div
                  :style="{ height: autoHeight * 0.51412 + 'px' }"
                  id="waterMaskId"
                >
                  <iframe
                    :src="sourceContent"
                    allowfullscreen="true"
                    style="width: 100%; height: 100%"
                  ></iframe>
                </div>
              </div>
              <div
                style="width: 100%; height: 100%; overflow: auto"
                v-show="
                  sourceContent !== '' && sourceContent.indexOf('/') == -1
                "
              >
                <LookPpt
                  @changeTaskByChild="changeTaskByChild"
                  ref="pptIframe"
                  :content="sourceContent"
                />
              </div>
              <div v-show="!sourceContent">
                <div
                  :style="{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: autoHeight * 0.61412 + 'px'
                  }"
                >
                  暂无课件
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane
              v-if="taskInfo.type != '课件'"
              label="任务详情"
              name="second"
            >
              <el-card style="line-height: 50px; margin-top: 10px">
                <span>{{ taskInfo.title }} </span>
                <span class="customBut" style="margin-left: 15px">
                  {{ taskInfo.type }}</span
                >
                <span
                  class="customButone"
                  v-if="taskInfo.practicePurpose === 'Y'"
                >课堂练习</span
                >
                <span class="customButtwo" v-if="taskInfo.learnPurpose === 'Y'"
                >学员可用</span
                >
                <span
                  v-if="showTaskFlag && (currenttaskID || taskInfo.sourceId)"
                  style="float: right"
                ><i
                  class="el-icon-full-screen"
                  size="24"
                  @click="previewTasks"
                ></i
                ></span>
              </el-card>
              <el-card style="margin-top: 10px">
                <previewTaskShow
                  v-if="showTaskFlag === 1 && currenttaskID"
                  :style="{ height: autoHeight * 0.52 + 'px' }"
                  :taskID="currenttaskID"
                />
                <pdfpreviewShow
                  v-if="showTaskFlag === 2 && currenttaskID"
                  :style="{ height: autoHeight *  0.52 + 'px' }"
                  style="width: 100%; overflow-y: scroll"
                  :taskID="currenttaskID"
                />
                <previewVideoShow
                  v-if="showTaskFlag === 3"
                  :sourceId="taskInfo.sourceId"
                />
                <div v-if="showTaskFlag === 4" style="padding: 16px">
                  <el-popover
                    @hide="formPygame.title = ''"
                    placement="top-start"
                    width="400"
                    trigger="hover"
                    v-model="pygameTitleVisible"
                  >
                    <div>
                      <el-form
                        size="small"
                        :inline="false"
                        :model="formPygame"
                        class="demo-form-inline"
                        @submit.native.prevent
                      >
                        <el-form-item label="作品名称">
                          <el-input
                            v-model="formPygame.title"
                            placeholder="作品名称"
                          ></el-input>
                        </el-form-item>
                        <el-form-item style="text-align: center">
                          <el-button
                            style="margin: 0 16px"
                            size="small"
                            type="primary"
                            @click="submitPygame('1')"
                          >
                            确定
                          </el-button>
                          <el-button
                            size="small"
                            @click="pygameTitleVisible = false"
                          >
                            取消
                          </el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                    <el-button
                      slot="reference"
                      size="small"
                      type="primary"
                      style="border-radius: 16px; margin-right: 16px"
                    >
                      提交
                    </el-button>
                  </el-popover>
                  <el-button
                    size="small"
                    type="info"
                    style="border-radius: 16px"
                    @click="showPygameRecord"
                  >
                    提交记录
                  </el-button>
                </div>

                <div v-if="false" style="padding: 16px">
                  <el-popover
                    @hide="formScratchJr.title = ''"
                    placement="top-start"
                    width="400"
                    trigger="hover"
                    v-model="scratchJrTitleVisible"
                  >
                    <div>
                      <el-form
                        size="small"
                        :inline="false"
                        :model="formScratchJr"
                        class="demo-form-inline"
                        @submit.native.prevent
                      >
                        <el-form-item label="作品名称">
                          <el-input
                            v-model="formScratchJr.title"
                            placeholder="作品名称"
                          ></el-input>
                        </el-form-item>
                        <el-form-item style="text-align: center">
                          <el-button
                            style="margin: 0 16px"
                            size="small"
                            type="primary"
                            @click="submitScratchJr('1')"
                          >
                            确定
                          </el-button>
                          <el-button
                            size="small"
                            @click="scratchJrTitleVisible = false"
                          >
                            取消
                          </el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                    <el-button
                      slot="reference"
                      size="small"
                      type="primary"
                      style="border-radius: 16px; margin-right: 16px"
                    >
                      提交
                    </el-button>
                  </el-popover>
                  <el-button
                    size="small"
                    type="info"
                    style="border-radius: 16px"
                    @click="showPygameRecord"
                  >
                    提交记录
                  </el-button>
                </div>
                <previewPygameShow
                  @previewPygame="previewPygame"
                  ref="pygameFrame"
                  :style="{ 'height': (autoHeight *  0.52) + 'px','overflowY': 'auto' }"
                  v-if="showTaskFlag === 4"
                  :sourceId="taskInfo.sourceId"
                  :taskInfo="taskInfo"
                  source="任务"
                />
                <previewScratchJrShow
                  v-if="showTaskFlag === 5"
                  :style="{ 'height': (autoHeight *  0.52) + 'px','overflowY': 'auto' }"
                  :taskInfo="taskInfo"
                  :sourceId="taskInfo.sourceId"
                />
              </el-card>
            </el-tab-pane>
          </el-tabs>

          <el-card class="jiaoan">
            <span class="titleName" style="font-size: 15px"> 备课教案 </span>
            <el-button
              size="mini"
              type="primary"
              style="margin-top: 10px; float: right"
              @click="taskprepareEdit"
            >保存
            </el-button>
            <el-input
              style="padding: 10px"
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="textarea"
            >
            </el-input>

          </el-card>
        </el-col>
        <el-col
          :span="rightauto"
          style="margin-left: 20px"
          v-if="!pickRightFlag"
        >
          <el-card>
            <el-tabs v-model="activeName" @tab-click="handleChange">
              <div style="height: 20px">
                <i
                  v-if="
                    (activeName == 'videoTwo' && statusone !== '') ||
                    (activeName == 'videoOne' && status !== '')
                  "
                  class="el-icon-full-screen"
                  style="float: right; font-size: 18px; margin-right: 10px"
                  @click="previewVideo"
                ></i>
                <i
                  class="el-icon-s-unfold"
                  style="float: right; font-size: 18px"
                  @click="pickupRight"
                ></i>
              </div>

              <el-tab-pane label="说课视频" name="videoTwo">
                <div v-if="statusone !== ''">
                  <div style="margin: 0 auto" id="player2"></div>
                  <div style="margin: 10px;line-height: 40px" v-if="currentArray.length > 1">
                    <el-button  v-for="(item,index) in currentArray" size="mini" :key="index"
                               :class="curentOneIndex === index?'active':''" round @click="changeVideo(index)">
                      {{ item.title }}
                    </el-button>
                  </div>
                </div>
                <div
                  v-else
                  :style="{ height: autoHeight * 0.25 + 'px' }"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  暂无视频
                </div>
              </el-tab-pane>
              <el-tab-pane label="串讲视频" name="videoOne">
                <div v-if="status !== ''">
                  <div style="margin: 0 auto" id="player1"></div>
                  <div style="margin: 10px;line-height: 40px" v-if="currentArray.length > 1">
                    <el-button v-for="(item,index) in currentArray" size="mini" :key="index"
                               :class="curentOneIndex === index?'active':''" round @click="changeVideo(index)">
                      {{ item.title }}
                    </el-button>
                  </div>
                </div>
                <div
                  v-else
                  :style="{ height: autoHeight * 0.25 + 'px' }"
                  style="
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                >
                  暂无视频
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-card>
          <el-card
            :style="{
              height: autoHeight * 0.55 + 'px',
              overflowY: 'auto',
            }"
            style="padding-top: 15px; margin-top: 10px"
          >
            <span class="titleName" style="font-size: 15px"> 课程教案 </span>
            <div id="result"></div>
            <!-- <p  style="font-size: 14px;line-height: 25px;">
              {{teacherPlan}}
            </p> -->
          </el-card>
        </el-col>
        <el-col :span="0.5" v-else style="margin-top: 20px">
          <i
            class="el-icon-s-fold"
            style="font-size: 18px"
            @click="spreadOutRight"
          ></i>
        </el-col>
      </el-row>
      <el-row style="margin-top: 60px" v-else>
        <div v-if="currenttaskID && pdfList.length !== 0">
          <pdfpreviewShow
            ref="pdfKejian"
            @reloadPover="reloadPover"
            :taskID="currenttaskID"
          ></pdfpreviewShow>
          <div style="position: fixed; right: 0; z-index: 2000; top: 150px">
            <el-popover placement="left" trigger="click">
              <div class="kejianpanrent">
                <span
                  class="kejianitem"
                  @click="itemClick(index, item.id)"
                  :class="currentKejianIndex === index ? 'kejianactive' : ''"
                  v-for="(item, index) in pdfList"
                  :key="index"
                >
                  {{ index + 1 }} . {{ item.title }}
                </span>
              </div>
              <el-button slot="reference" round
              ><i class="el-icon-menu"></i>课件
              </el-button
              >
            </el-popover>
          </div>
        </div>
        <el-row style="text-align: center; margin-top: 18%" v-else>
          暂无课件
        </el-row>
      </el-row>
    </div>
    <el-dialog
      title="Pygame提交记录"
      :visible.sync="pygameRecordModal"
      width="800px"
      append-to-body
    >
      <PygameRecord
        v-if='pygameRecordModal'
        :courseTask="courseTask"
        @loadToEdit="previewPygame"
        ref="pygameRecord"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  courseUnitInfo,
  taskList,
  listAttachment,
  taskprepareGetInfo,
  taskprepareEdit,
  getCourseInfo,
} from '@/apiteacher/teacher'
import previewTaskShow from '@/componentsTeacher/previewTask'
import pdfpreviewShow from '@/componentsTeacher/pdfpreview'
import previewVideoShow from '@/componentsTeacher/previewVideo/indexs'
import previewPygameShow from '@/views/Pygame/tasksub'
import previewScratchJrShow from '@/views/ScratchJr/prepare'
import { getVideoToken } from '@/api/businesscourse/video'
import PygameRecord from './components/PygameRecord'
import LookPpt from '@/views/PPT/look'

export default {
  components: {
    previewTaskShow,
    pdfpreviewShow,
    previewVideoShow,
    previewPygameShow,
    previewScratchJrShow,
    LookPpt,
    PygameRecord,
  },

  mounted () {
    this.getCourseInfo()
    const that = this

    /**
     * 获取课节详情
     */
    courseUnitInfo({ courseUnitId: this.$route.query.courseUnitId })
      .then((res) => {
        res.state == 'success' && res.body.prepare == 'Y'
          ? initFun()
          : minFun()
      })
      .catch((err) => {
        minFun()
      })

    /**
     * 无权限返回备课列表
     */
    function minFun () {
      that.$message({
        message: '暂不可备课',
        type: 'warning',
        duration: 2000,
        onClose: () => {
          window.opener = null
          window.open('about:blank', '_top').close()
        },
      })
    }

    /**
     * 初始化备课界面
     * 禁止在未检验状态下拉取课程资料
     */
    function initFun () {
      that.courseTitle = that.$route.query.courseTitle
      that.autoHeight = document.documentElement.clientHeight
      that.rightauto = parseInt(
        400 / (document.documentElement.clientWidth / 24)
      )
      that.middleWidth = 19 - that.rightauto
      that.tasLeftkList()
      that.$nextTick(() => {
        that.$watermark.set('可达鸭编程', 'waterMaskId')
      })
    }
  },
  watch: {
    teacherPlan: {
      handler (val) {
        if (document.getElementById('result')) {
          if (val) {
            document.getElementById('result').innerText = val
          } else {
            document.getElementById('result').innerText = '暂无教案'
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data () {
    return {
      courseTask: {
        //pygame记录专用
        // title: this.formPygame.title,
        // taskId: this.currenttaskID,
        // courseId: this.courseInfo.id,
        // saveType: flag == "1" ? "save" : "other",
      },
      pygameRecordModal: false,
      formPygame: {}, //作品名称
      formScratchJr: {}, //作品名称
      pygameTitleVisible: false,
      scratchJrTitleVisible: false,
      currentKejianIndex: 0,
      dialogTaskVisible: false, //screen
      dialogCourseVisible: false, //screen
      dialogVideoVisible: false, //screen
      showTaskFlag: 1,
      rightauto: 5,
      watershowid: '1.23452384164.123412415',
      middleWidth: 14,
      pickRightFlag: false,
      pickLeftFlag: false,
      courseTitle: '',
      sourceContent: '',
      activeNameTab: 'first',
      currentIndex: 0,
      autoHeight: 0,
      taskListArray: [],
      textarea: '',
      activeName: 'videoTwo',
      status: '',
      statusone: '',
      curentOneIndex: 0,
      currentArray: [],

      videoShuoArray: [],
      videoChuanArray: [],
      // optionsVideo: [],
      currenttaskID: '',
      teacherPlan: '',
      taskInfo: {},
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: '', //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: '', //url地址
          },
        ],
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      currentTaskListInfo: {},
      // 视频token vid
      vidInfo1: null,
      vidInfo2: null,
      vodPlayerJs: 'https://player.polyv.net/script/player.js',
      player: null,
      videoEleId1: '#player1',
      videoEleId2: '#player2',
      pptPath: '',
      onlinePpt: 'N',
      courseInfo: {},
      pdfList: [],
    }
  },
  methods: {
    // showPygameRecord
    showPygameRecord () {
      this.courseTask = {
        title: this.formPygame.title,
        taskId: this.currenttaskID,
        courseId: this.courseInfo.id,
      }
      this.pygameRecordModal = true
    },
    // 提交pygame
    submitPygame (flag) {
      if (!this.formPygame.title && flag == '1') {
        this.$message.warning('作品名称不可为空！')
        return
      }
      let courseTask = {
        title: this.formPygame.title,
        // name: this.formData.title,
        // flag: this.pygameChoose,
        taskId: this.currenttaskID,
        courseId: this.courseInfo.id,
        saveType: flag == '1' ? 'save' : 'other',
      }
      console.log(this.courseInfo, courseTask)
      this.$refs.pygameFrame.saveFile({ ...courseTask, info: this.taskInfo })
    },
    // 提交scratchJr
    submitScratchJr (flag) {
      if (!this.formScratchJr.title && flag == '1') {
        this.$message.warning('作品名称不可为空！')
        return
      }
      let courseTask = {
        title: this.formScratchJr.title,
        // name: this.formData.title,
        // flag: this.pygameChoose,
        taskId: this.currenttaskID,
        courseId: this.courseInfo.id,
        saveType: flag == '1' ? 'save' : 'other',
      }
      // console.log(this.courseInfo, courseTask)
      // this.$refs.scratchJrFrame.saveFile({ ...courseTask, info: this.taskInfo })
    },
    // 加载预知代码
    previewPygame (row) {
      let courseTask = {
        title: row.title,
        taskId: this.currenttaskID,
        courseId: this.courseInfo.id,
      }
      this.$refs.pygameFrame.initPygameProject(courseTask, row)
      this.pygameRecordModal = false
    },
    // 加载预知代码
    previewScratchJr (row) {
      let courseTask = {
        title: row.title,
        taskId: this.currenttaskID,
        courseId: this.courseInfo.id,
      }
      // this.$refs.scrathJrFrame.initScratchJrProject(courseTask, row)
      // this.pygameRecordModal = false
    },
    reloadPover () {
      document.body.click()
    },
    itemClick (index, taskId) {
      this.currentKejianIndex = index
      this.currenttaskID = taskId
      this.$refs.pdfKejian.getTaskInfo()
    },
    // 根据 id 获取课程基本信息
    async getCourseInfo () {
      const res = await getCourseInfo({
        id: this.$route.query.ci,
      })
      this.courseInfo = { ...res.body }
    },
    // 视频预览
    previewVideo () {
      let routes = this.$router.resolve({
        name: 'pvideos',
        query: {
          sourceVideoId:
            this.activeName == 'videoOne' ? this.status : this.statusone,
        },
      })
      window.open(routes.href, '_blank')
    },
    // 课件预览
    previewCourse () {
      if (this.onlinePpt == 'Y') {
        let routes = this.$router.resolve({
          path: '/ppt/looks',
          query: {
            content: this.sourceContent,
            ci: this.$route.query.ci,
            cn: this.$route.query.cn,
            ui: this.$route.query.ui,
            un: this.$route.query.un,
            ti: this.currentTaskListInfo.id,
            tsourceId: this.currentTaskListInfo.sourceId,
            tsort: this.currentTaskListInfo.sort,
          },
        })
        window.open(routes.href, '_blank')
      } else {
        let routes = this.$router.resolve({
          name: 'pcourses',
          query: { sourceContent: this.sourceContent },
        })
        window.open(routes.href, '_blank')
      }
    },
    // 任务预览
    previewTasks () {
      let routes = this.$router.resolve({
        name: 'ptaskss',
        query: {
          currenttaskID: this.currenttaskID,
          showTaskFlag: this.showTaskFlag,
          taskInfo: JSON.stringify(this.taskInfo),
        },
      })
      window.open(routes.href, '_blank')
    },
    /**
     * 课件详情
     */
    showCourseDetail () {
      this.dialogCourseVisible = true
    },
    /**
     * 任务详情
     */
    showTaskDetail () {
      this.dialogTaskVisible = true
    },
    /**
     * 视频详情
     */
    showVideoDetail () {
      this.dialogVideoVisible = true
    },
    changeVideo (index) {
      this.curentOneIndex = index
      console.log('index-------'+index)
      console.log('this.activeName-------'+this.activeName)
      if (this.activeName == 'videoOne') {
        this.changeChuanVideo(index)
      } else {
        this.changeShuoVideo(index)
      }
    },
    handleChange () {
      this.curentOneIndex = 0
      if (this.activeName == 'videoOne') {
        this.currentArray = this.videoChuanArray
        this.changeChuanVideo(0)
      } else {
        this.currentArray = this.videoShuoArray
        this.changeShuoVideo(0)
      }
    },
    spreadOutRight () {
      this.pickRightFlag = false
      this.middleWidth = this.middleWidth - 5
      this.$nextTick(() => {
        this.loadPlayerScript(this.loadPlayer)
      })
    },
    spreadOutLeft () {
      this.pickLeftFlag = false
      this.middleWidth = this.middleWidth - 4
    },
    pickupLeft () {
      this.pickLeftFlag = true
      this.middleWidth = this.middleWidth + 4
    },
    pickupRight () {
      this.pickRightFlag = true
      this.middleWidth = this.middleWidth + 5
    },
    async tasLeftkList () {
      const res = await taskList({
        courseUnitId: this.$route.query.courseUnitId,
      })
      this.taskListArray = res.body
      res.body.forEach((item) => {
        if (item.type === 'pdf') {
          this.pdfList.push(item)
        }
      })
      this.currentTaskListInfo = res.body[0]
      this.$nextTick(() => {
        let item = {}
        if (this.courseInfo.type === '视频') {
          item = this.pdfList[0]
        } else {
          item = this.taskListArray[0]
        }
        this.changeTask(item.id, 0, item)
      })
    },

    async listAttachmentVideo (taskID) {
      const res = await listAttachment({
        taskId: taskID,
        type: '串讲视频',
      })
      if (res.body.length !== 0) {
        this.videoChuanArray = res.body
        this.status = this.videoChuanArray[0].content
        this.changeChuanVideo(0)
      } else {
        this.status = ''
        this.playerOptions.sources[0].src = ''
      }
    },
    changeChuanVideo (index) {
      const params = {
        sourceVideoId: this.videoChuanArray[index].content,
      }
      getVideoToken(params).then((res) => {
        if (res.state == 'success') {
          this.vidInfo1 = JSON.parse(res.body.playToken)
          this.$nextTick(() => {
            if (this.vidInfo1.data && this.vidInfo1.data.token) {
              this.loadPlayerScript(this.loadPlayer)
            }
          })
        }
      })
    },
    async listAttachmentVideoTwo (taskID) {
      const res = await listAttachment({
        taskId: taskID,
        type: '补课视频',
      })
      if (res.body.length !== 0) {
        this.videoShuoArray = res.body
        this.currentArray = res.body
        this.statusone = this.videoShuoArray[0].content
        this.changeShuoVideo(0)
      } else {
        this.playerOptions.sources[0].src = ''
        this.statusone = ''
      }
    },

    changeShuoVideo (index) {
      const params = {
        sourceVideoId: this.videoShuoArray[index].content,
      }
      getVideoToken(params).then((res) => {
        if (res.state == 'success') {
          this.vidInfo2 = JSON.parse(res.body.playToken)
          this.$nextTick(() => {
            this.$nextTick(() => {
              if (this.vidInfo2.data && this.vidInfo2.data.token) {
                this.loadPlayerScript(this.loadPlayer)
              }
            })
          })
        }
      })
    },
    async listAttachmentCourseWare (taskID) {
      const res = await listAttachment({
        taskId: taskID,
        type: '课件',
      })
      // this.optionsVideo = res.body

      this.onlinePpt = 'N'
      if (res.body.length !== 0) {
        if (res.body[0].onlinePpt == 'Y') {
          this.onlinePpt = 'Y'
          this.sourceContent = res.body[0].content
          if (this.$refs.pptIframe && this.$refs.pptIframe.changeSlidesPage) {
            this.$refs.pptIframe.changeSlidesPage(taskID)
          }
        } else if (
          res.body[0].onlinePpt == 'N' &&
          res.body[0].content.indexOf('/') >= 0
        ) {
          this.sourceContent =
            process.env.VUE_APP_PREVIEW_ADDRESS + res.body[0].content
        } else {
          this.sourceContent = ''
        }
      } else {
        this.sourceContent = ''
        if (document.getElementById('waterMaskId')) {
          document
            .getElementById('waterMaskId')
            .removeChild(document.getElementById(this.watershowid))
        }
      }
    },
    async taskprepareGetInfo (taskID) {
      const res = await taskprepareGetInfo({
        taskId: taskID,
      })
      if (res.body === null) {
        this.textarea = ''
      } else {
        this.textarea = res.body.content
      }
    },
    async taskprepareEdit () {
      const res = await taskprepareEdit({
        taskId: this.currenttaskID,
        content: this.textarea,
      })
      if (res.state === 'success') {
        window.$msg('提交备案成功')
      }
    },
    changeTaskByChild (taskID) {
      let item, index
      this.taskListArray.forEach((i, j) => {
        if (i.id === taskID) {
          item = i
          index = j
        }
      })
      if (item) {
        this.changeTask(taskID, index, item)
      }
    },
    changeTask (taskID, index, item) {
      // console.log(taskID, index, item);
      // if(this.$refs.pptIframe && this.$refs.pptIframe.changeSlidesPage){
      //   this.$refs.pptIframe.changeSlidesPage(taskID)
      // }
      // console.log(item)
      this.showTaskFlag = 0;
      this.activeNameTab = 'first'
      this.taskInfo = item
      this.currentIndex = index + 1
      this.currenttaskID = taskID
      if (this.courseInfo.type !== '视频') {
        this.teacherPlan = this.taskListArray[index].teacherPlan
        this.listAttachmentVideo(taskID)
        this.listAttachmentVideoTwo(taskID)
        this.listAttachmentCourseWare(taskID)
        this.taskprepareGetInfo(taskID)
      }
      switch (item.type) {
        case 'pdf':
          this.showTaskFlag = 2
          break
        case '视频':
          this.showTaskFlag = 3
          break
        case 'Pygame':
          this.showTaskFlag = 4
          break
        case 'scratchjr':
          this.showTaskFlag = 5
          break
        default:
          this.showTaskFlag = 1
      }
    },
    showVideo (sourceVideoId) {
      const params = {
        sourceVideoId,
      }
      getVideoToken(params).then((res) => {
        if (res.state == 'success') {
          this.vidInfo = JSON.parse(res.body.playToken)
          this.vid = res.body.videoId
          this.$nextTick(() => {
            this.loadPlayerScript(this.loadPlayer)
          })
        }
      })
    },
    loadPlayerScript (callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.vodPlayerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
      } else {
        callback()
      }
    },

    loadPlayer () {
      this.destroyed()
      const polyvPlayer = window.polyvPlayer
      this.player = polyvPlayer({
        wrap:
          this.activeName == 'videoOne' ? this.videoEleId1 : this.videoEleId2,
        // width: 800,
        height: 200,
        vid:
          this.activeName == 'videoOne'
            ? this.vidInfo1.data.videoId
            : this.vidInfo2.data.videoId,
        playsafe:
          this.activeName == 'videoOne'
            ? this.vidInfo1.data.token
            : this.vidInfo2.data.token,
        code:
          '可达鸭编程—' +
          localStorage.getItem('userName') +
          '|' +
          localStorage.getItem('token_business'),
      })
    },
    destroyed () {
      if (this.player) {
        this.player.destroy()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../style/container.scss";

/deep/ .jiaoan {
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 100px;
}

/deep/ .parentScroll {
  overflow-y: scroll;
}

/deep/ .titleName {
  font-size: 20px;
  font-weight: 600;
}

/deep/ .box-card {
  line-height: 60px;
}

/deep/ .taskitem {
  font-size: 14px;
  line-height: 40px;
}

/deep/ .line {
  background: #cecece;
  width: 100%;
  height: 1px;
  margin-top: 10px;
}

/deep/ .customBut {
  text-align: center;
  background: #f9f0ff;
  color: #722ed1;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/deep/ .customButone {
  text-align: center;
  background: #fff2e8;
  color: #fa541c;
  margin-left: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 10px;
}

/deep/ .customButtwo {
  text-align: center;
  background: #f6ffed;
  color: #52c41a;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/deep/ .el-dialog__body {
  padding: 10px 0 0 0;
}

/deep/ .inner-style {
  border: 1px solid #eee;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.videotoptitle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.kejianpanrent {
  display: flex;
  flex-direction: column;
  line-height: 30px;
}

.kejianitem {
  cursor: pointer;
}

.kejianactive {
  color: #f7cf47;
}

.active {
  background: #F7CF47 !important;
  color: #FFFFFF;
}
</style>

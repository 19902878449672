var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "box-code",
    },
    [
      _c("div", {
        staticClass: "navs",
        staticStyle: { "background-color": "#f5c319", display: "none" },
      }),
      _c("iframe", {
        ref: "iframe",
        staticClass: "iframe-style",
        attrs: {
          allowfullscreen: "true",
          id: "pygameiframe",
          src: _vm.iframeWinPath,
        },
      }),
      _c("WorkEditor", {
        ref: "editor",
        attrs: { formInfos: _vm.formInfo, rules: _vm.rules },
        on: { saveFile: _vm.saveFile },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "个人素材库",
            visible: _vm.sourseModal,
            width: "900px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sourseModal = $event
            },
          },
        },
        [
          _c("SourseList", {
            ref: "sourse",
            on: { loadToEdit: _vm.loadToEditBefoure },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材重命名",
            visible: _vm.renameModal,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.renameModal = $event
            },
          },
        },
        [
          _vm.renameModal
            ? _c("SourseRename", {
                ref: "name",
                attrs: { formInfos: _vm.currentRenameInfo },
                on: {
                  cancel: function ($event) {
                    _vm.renameModal = false
                  },
                  renameFile: _vm.renameFileBefore,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片素材大小设置",
            visible: _vm.sizeSetModal,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sizeSetModal = $event
            },
          },
        },
        [
          _vm.sizeSetModal
            ? _c("SourseSizeSet", {
                ref: "size",
                attrs: { formInfos: _vm.currentRenameInfo },
                on: {
                  cancel: function ($event) {
                    _vm.sizeSetModal = false
                  },
                  sizeSetFile: _vm.sizeSetFile,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "素材预览",
            visible: _vm.previewModal,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewModal = $event
            },
          },
        },
        [
          _vm.previewModal
            ? _c("SourcePreview", {
                ref: "preview",
                attrs: { scanInfo: _vm.currentRenameInfo },
                on: {
                  cancel: function ($event) {
                    _vm.previewModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "确认移除", visible: _vm.removeModal, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.removeModal = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v("移除后不可恢复，确认移除吗？"),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.removeModal = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.removeSource } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }